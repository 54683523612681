.dialog {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	max-width: $notificationMaxWidth;
	height: auto;
	z-index: 2;

	&__container {
		background-color: $notificationColor;
		border: 1px solid currentColor;
		color: $notificationBgColor;
		padding: $base4;
		width: 100%;
	}

	&__content {
		height: 100%;
	}

	&__title {
		@include font(base);
		padding-bottom: $base2;
		margin-bottom: 0;
	}

	&__text {
		position: relative;
		@include font(base);
		padding-bottom: $base2;

		.dialog__title + & {
			padding-top: $base2;
			border-top: 1px solid $baseLineColor;
		}
	}

	&__buttons {
		padding-top: $base4;
		@include font(base);
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	&__buttonItem {
		flex-shrink: 0;
		flex-grow: 0;

		& + & {
			margin-left: $base2;
		}
	}
}
