.block {
	&__fullCaption {
		@include app-container();
	}


	&__fullFigure {
		width: 100%;

		&--portrait {
			@include media('landscape') {
				display: none;
			}
		}

		&--landscape {
			@include media('portrait') {
				display: none;
			}
		}
	}


	&__fullImg {
		width: 100%;
	}
}
