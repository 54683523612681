.application {
	position: relative;

	&__info {
		display: block;
		position: sticky;
		left: 0;
		top: 0;
		background-color: $baseBgColor;
		padding: $base2 0;
		z-index: 200;

		.body--embedded & {
			display: none;
		}
	}

	&__tabs {
		position: relative;
		max-width: $textMaxWidth;
	}

	// comment
	&__progress {
		position: absolute;
		right: 0;
		top: $base2;
	}

	&__sections {
		position: relative;
		overflow: hidden;

		.body--embedded & {
			overflow: visible;
		}
	}

	&__subtitle {
		.block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

			@include media($columnsBreakpoint) {
				margin-top: $blockVerticalPadding;
			}
		}
	}

	&__text {
		* + & {
			margin-top: $base5;
		}

		.block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

			@include media($columnsBreakpoint) {
				margin-top: $blockVerticalPadding;
			}
		}

		a:hover {
			border-bottom: 1px solid currentColor;
		}
	}
}
