.dialogContext {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 500;
	align-items: center;
	justify-content: center;
	display: none;
	visibility: hidden;
	opacity: 0;
	@include ease(visibility opacity, $duration4);

	&.js-beforeActive {
		display: block;
	}


	&.js-duringActive {
		visibility: visible;
		opacity: 1;
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
		background: rgba($baseColor, 0.9);
	}
}
