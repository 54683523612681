.form {
	position: relative;
	width: 100%;
	margin: auto;

	* + & {
		margin-top: $base4;

		.body--embedded & {
			margin-top: 0;
		}
	}

	&--singleAppform {
		display: flex;
		flex-direction: column;
	}

	&__fields {
		position: relative;
		z-index: 1;

		@include media($columnsBreakpoint) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-left: -$base2;
			margin-top: -$base3;
		}

		.form--singleAppform & {
			order: 2;
		}
	}

	&__actions {
		position: relative;
		z-index: 10;
		width: 100%;
		left: 0;
		bottom: 0;
		margin-top: $base5;
		padding-bottom: $base5;

		.form[data-form='application/add'] & {
			padding-bottom: 0;
		}

		.form--singleAppform & {
			order: 3;
		}
	}

	&__actionsItems {
		display: flex;

		flex-direction: column;

		@include media('>=small') {
			flex-direction: row;
			justify-content: space-between;
			align-items: stretch;
		}
	}

	&__actionsGroup {
		flex-shrink: 0;
		flex-grow: 0;
		width: 100%;

		@include media('>=small') {
			width: auto;
		}

		&--full {
			@include media('>=small') {
				width: 100%;
			}
		}

		& + & {
			margin-top: $base2;

			@include media('>=small') {
				margin-top: 0;
				margin-left: $base2;
			}
		}
	}

	&__actionsGroupItems {
		display: flex;
		flex-direction: column;

		@include media('>=small') {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		&--alignStart {
			justify-content: flex-start;
		}

		&--alignCenter {
			justify-content: center;
		}

		&--alignEnd {
			justify-content: flex-end;
		}

		.form__actionsGroup--accountExtraActions & {
			flex-direction: column;
			justify-content: flex-start;

			@include media('>=small') {
				align-items: flex-end;
			}
		}
	}

	&__actionItem {
		flex-shrink: 0;
		flex-grow: 0;

		& + & {
			margin-top: $base2;

			@include media('>=small') {
				margin-top: 0;
				margin-left: $base2;
			}
		}

		&--register + & {
			@include media('>=small') {
				margin-top: $base2;
			}
		}
	}

	&__actionItemDesc {
		padding-top: $base;
	}
}
