.applications {
	padding-bottom: $base7;

	&--embedded {
		padding-bottom: 0;
	}

	.block__text + & {
		margin-top: $base5;
	}

	&__item {
		padding: $base2 0;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		border-bottom: 1px solid currentColor;

		&:first-child {
			border-top: 1px solid currentColor;
		}
	}

	&__itemTitle {
		width: calc(50% - #{$base4});
	}

	&__itemActions {
		width: calc(50% - #{$base4});
		text-align: right;
		align-self: flex-start;
	}

	&__itemPdf {
		position: relative;
		margin: $base 0 0 $base2;
	}

	&__itemPdfs {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		margin: $base 0 $base2 (-$base2);
	}

	&__itemProgress {
		width: $base8;
		text-align: center;
		align-self: flex-start;
	}

	&__itemName {
		display: block;
	}

	&__itemAppform {
		@include font(detail);
		display: block;
		opacity: 0.7;
	}

	&__appformDescriptions {
		position: relative;
		margin-top: $base3;

		.form--singleAppform & {
			order: 1;
			margin-bottom: $base3;
			margin-top: 0;
		}
	}

	&__appformDescription {
		@include ease(visibility opacity, $duration2);
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		max-width: $textMaxWidth;

		&.js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}
	}

	&__itemAction {
		margin-left: $base2;

		&:first-child {
			margin-left: 0;
		}
	}

	&__linkedApp {
		@include font(detail);
		list-style-type: '-';
		padding-left: $base;
		margin-left: $base2;
		margin-top: $base;
	}

	&__submittedIcon {
		position: relative;
		display: inline-block;
		width: $base3;
		height: $base3;

		span[data-type='svg'] {
			width: 100%;
			height: 100%;
		}
	}

	&__text {
		* + & {
			margin-top: $base5;
		}
	}

	&__embeddedDesc {
		margin-bottom: $base2;
	}
}
