
@keyframes progress {
	0% {
		opacity: 1;
		transform: scale(1);
	}


	100% {
		opacity: 0;
		transform: scale(2);
	}
}
