@use 'sass:math';
.block {
	&__bgImg {
		position: absolute;
		top: -1px; //NOTE: yes px :-)
		right: -1px;
		bottom: -1px;
		left: -1px;

		body.js-highContrast .block:not(.block--video) & {
			display: none;
			// opacity: 0.1;
		}

		html.no-object-fit & {
			overflow: hidden;
		}

		.block--publication & {
			position: static;
			max-width: $blockContentMaxWidth;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		.block--video & {
			position: relative;
			width: 100%;
			left: auto;
			right: auto;
			top: 0;
			bottom: auto;
			order: 0;

			background-color: $invertedBgColor;
			max-width: $blockContentMaxWidth;
			margin: 0 auto;

			&::before {
				content: ' ';
				display: block;
				padding-bottom: math.div(1, $videoPlayerRatio) * 100%;
			}

			.overlay & {
				left: 0;
				right: 0;
				max-width: none;
			}

			.blocks--columns & {
				margin-top: $containerPaddingSmall;
				margin-bottom: $containerPaddingSmall;

				@include media($columnsBreakpoint) {
					margin-top: $containerPadding;
					margin-bottom: $containerPadding;
				}
			}
		}

		img {
			opacity: $blockBgImgOpacity;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			left: 0;
			top: 0;

			.block--bgImgContain & {
				object-fit: contain;
			}

			html.no-object-fit & {
				width: auto;
				height: auto;
				min-width: 100%;
				min-height: 100%;
			}

			.block--bgImgNoFilter & {
				opacity: 1;
			}

			.block--publication & {
				opacity: 1;
				position: relative;
			}

			.block--video & {
				opacity: 1;
				position: absolute;
				top: 0;
				left: 0;
			}

			.block--video.js-visiblePlayer & {
				display: none;
			}

			.block--teaser &,
			.block--search & {
				position: absolute;
				left: 50%;
				top: 0;
				transform-origin: 50% 0;
				transform: translateX(-50%);
				backface-visibility: hidden;
				transform-style: preserve-3d;
			}

			.block--teaser.js-insideViewport &,
			.block--search.js-insideViewport & {
				will-change: transform;
			}
		}

		figcaption {
			@include app-container;
			@include font(detail);
			margin-top: 0;
			position: relative;
			transform: translateY(-100%);
			padding-bottom: $containerPaddingSmall;
			color: inherit;
			opacity: $blockBgImgCaptionOpacity;

			max-width: $blockContentMaxWidth + $containerPaddingSmall + $containerPaddingSmall;

			@include media($columnsBreakpoint) {
				max-width: $blockContentMaxWidth + $containerPadding + $containerPadding;
				padding-bottom: $containerPadding;
			}

			.block--video &,
			.block--teaser &,
			.block--search & {
				position: absolute;
				bottom: 0;
				transform: none;
				max-width: none;
			}
		}
	}
}
