@use 'sass:math';
.block {
	@mixin removeAdjacentSpace($scheme) {
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--noBgImg.block--colorScheme#{$scheme},
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--video.block--colorScheme#{$scheme},
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--publication.block--colorScheme#{$scheme},
		&--video.block--colorScheme#{$scheme} + &--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile),
		&--publication.block--colorScheme#{$scheme} + &--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) {
			padding-top: 0;
		}
	}

	@mixin removeExtraTileSpace($scheme) {
		&--tilesWall.block--colorScheme#{$scheme} {
			.block--noBgImg.block--colorScheme#{$scheme},
			.block--publication.block--colorScheme#{$scheme},
			.block--videoWithoutContent.block--colorScheme#{$scheme} {
				padding: 0;
			}
		}
	}

	@include app-container;
	max-width: none;
	display: block;
	position: relative;
	overflow: hidden;
	color: $baseColor;

	padding-top: $blockMobileVerticalPadding;
	padding-bottom: $blockMobileVerticalPadding;

	.body--embedded & {
		padding-top: $base5;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;

		@include media($columnsBreakpoint) {
			padding-top: $base5;
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}

	@include media($columnsBreakpoint) {
		padding-top: $blockVerticalPadding;
		padding-bottom: $blockVerticalPadding;
	}

	// remove adjacent margins when they have the same colors
	@include removeAdjacentSpace('Default');
	// @include removeAdjacentSpace('Inverted');
	@include removeAdjacentSpace('Alternative');

	&--slideshow {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;

		&.block--widthMax {
			padding-top: 0;

			@include media($columnsBreakpoint) {
				padding-left: 0;
				padding-right: 0;
				padding-top: 0;
			}
		}

		&.block--widthDefault {
			@include media($columnsBreakpoint) {
				padding-left: $containerPadding;
				padding-right: $containerPadding;
				padding-top: $blockVerticalPadding;
			}
		}
	}

	&--fullImg {
		padding-left: 0;
		padding-right: 0;
	}

	&--bgVideo,
	&--fullSize {
		padding: 0;

		@include media($columnsBreakpoint) {
			padding: 0;
		}
	}

	&--noVerticalMargins {
		padding-bottom: 0;
		padding-top: 0;

		@include app-blocksFirstBlock {
			padding-top: $headerMobileHeight;

			@include media($columnsBreakpoint) {
				padding-top: $headerHeight;
			}
		}
	}

	&--noBgImg {
		overflow: visible;
	}

	&__titleImage + .blocks__subBlocks > &--noBgImg.block--colorSchemeDefault:first-child,
	&__titleImage + .blocks__subBlocks > &--video.block--colorSchemeDefault:first-child,
	&__titleImage + .blocks__subBlocks > &--publication.block--colorSchemeDefault:first-child {
		padding-top: 0;
	}

	.page--applicationEdit & {
		overflow: visible;
	}

	@include removeExtraTileSpace('Default');
	@include removeExtraTileSpace('Inverted');
	@include removeExtraTileSpace('Alternative');

	.block--tilesWall > .block__content > &--videoWithoutContent {
		padding: 0;

		@include media($columnsBreakpoint) {
			padding: 0;
		}
	}

	// &--noBgImg.block--colorSchemeDefault,
	// &--noBgImg.block--colorSchemeInverted,
	// &--noBgImg.block--colorSchemeAlternative {
	// 	&:not(.blockTilesWall__tile) + &:not(.blockTilesWall__tile) {
	// 		padding-top: 0;
	// 	}
	// }

	&--tile {
		margin-bottom: $base2;

		@include media($columnsBreakpoint) {
			margin-bottom: $base3;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&--colorSchemeDefault {
		color: $baseColor;
		background-color: $baseBgColor;
	}

	&--colorSchemeAlternative {
		color: $alternativeColor;
		background-color: $alternativeBgColor;
	}

	&--colorSchemeInverted {
		color: $invertedColor;
		background-color: $invertedBgColor;

		&:not(.block--tile):not(.block--bgImg):not(.block--slideshow) {
			margin-top: 1px;
			margin-bottom: 1px;
		}

		&:not(.block--tile):first-child {
			margin-top: 0;
		}
	}

	&--alignLeft {
		text-align: left;
	}

	&--alignCenter {
		text-align: center;
	}

	&--alignRight {
		text-align: right;
	}

	.overlay & {
		padding-left: 0;
		padding-right: 0;

		@include media($columnsBreakpoint) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&:not(.block--bgVideo):not(.block--noVerticalMargins) {
		@include app-blocksFirstBlock() {
			.all & {
				padding-top: #{$headerMobileHeight + $headerSpacingSmall * 2 + $blockMobileVerticalPadding};

				@include media($columnsBreakpoint) {
					padding-top: #{$headerHeight + $headerSpacingLarge * 2 + $blockVerticalPadding};
				}

				.body--embedded & {
					padding-top: $base5;

					@include media($columnsBreakpoint) {
						padding-top: $base5;
					}
				}
			}

			.overlay & {
				padding-top: 0;
				// padding-top: $containerPaddingSmall;

				@include media($columnsBreakpoint) {
					padding-top: 0;
					// padding-top: $containerPadding;
				}
			}

			.all.js-subMenu & {
				padding-top: #{$headerMobileHeight * 1.5 + $blockMobileVerticalPadding};

				@include media($columnsBreakpoint) {
					padding-top: #{$headerHeight * 1.5 + $blockVerticalPadding};
				}
			}
		}
	}

	.blocks--columns & {
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;

		@include media($columnsBreakpoint) {
			padding-top: $containerPadding;
			padding-bottom: $containerPadding;
		}
	}

	.block--tilesWall & {
		padding-top: $base;
		padding-bottom: $base;

		@include media($columnsBreakpoint) {
			padding-top: $base2;
			padding-bottom: $base2;
		}
	}

	.blocks--pageActions &:nth-last-child(2) {
		padding-bottom: 0;

		@include media($columnsBreakpoint) {
			padding-bottom: 0;
		}
	}

	&--teaser,
	&--search {
		padding-top: $blockMobileVerticalPadding + $base3;
		padding-bottom: $blockMobileVerticalPadding + $base3;

		@include media($columnsBreakpoint) {
			padding-top: $blockVerticalPadding + $base6;
			padding-bottom: $blockVerticalPadding + $base6;
		}

		.block--tilesWall & {
			padding-top: $blockMobileVerticalPadding;
			padding-bottom: $blockMobileVerticalPadding;

			@include media($columnsBreakpoint) {
				padding-top: $blockVerticalPadding;
				padding-bottom: $blockVerticalPadding;
			}
		}
	}

	&--pageActions {
		padding-top: 0;

		@include media($columnsBreakpoint) {
			padding-top: 0;
		}
	}

	&--tagSelection {
		padding-top: $blockMobileVerticalPadding + $base1;
		padding-bottom: $base1;

		@include media($columnsBreakpoint) {
			padding-top: $blockVerticalPadding + $base1;
			padding-bottom: $base1;
		}
	}

	&--bgImg {
		min-height: math.div(100vw, $blockBgImageRatio);

		.block--tilesWall &,
		&.block--linkedTile,
		&.block--video,
		&.block--publication {
			min-height: 0;
		}
	}

	&--linkedTile {
		&,
		.block--tilesWall & {
			padding-top: $blockMobileVerticalPadding + $base8;
			padding-bottom: $blockMobileVerticalPadding + $base8;

			@include media($columnsBreakpoint) {
				padding-top: $blockVerticalPadding + $base10;
				padding-bottom: $blockVerticalPadding + $base10;
			}
		}
	}

	&__mainLink {
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	&__pageTitle {
		@include font(firstHeadline);
		display: flex;
		justify-content: space-between;
		margin-bottom: $base3;

		@include media($columnsBreakpoint) {
			margin-bottom: $base5;
		}

		.body--embedded .page--applicationPreview &,
		.body--embedded &--confirmAppTitle {
			display: none;
		}
	}

	&__titleImage {
		text-align: center;
		// margin-bottom: $base7;

		img {
			width: 100%;
		}
	}

	&__subTitle {
		@include font(secondHeadline);
		display: block;

		.block--tilesWall & {
			@include font(base);
		}

		.block__titleImage + &,
		.block__title + & {
			margin-bottom: $base2;
		}
	}

	//NOTE: generalize likList!
	&__links {
		margin-left: auto;
		margin-right: auto;

		&:not(:first-child) {
			margin-top: $base3;

			@include media('>xSmall') {
				margin-top: $base7;
			}
		}
	}

	&__linkItem {
		max-width: 100%;
		margin-bottom: $base;

		@include media('>xSmall') {
			display: inline-block;
			max-width: calc(100% - #{$base});
			margin-left: $base * 0.5;
			margin-right: $base * 0.5;
		}
	}

	&__logos {
		// max-width: $textMaxWidth;

		&:not(:first-child) {
			margin-top: $base5;

			@include media('>xSmall') {
				margin-top: $base10;
			}
		}
	}

	&__icon {
		text-align: center;
		padding-top: $base2;

		@include media('>xSmall') {
			padding-top: $base5;
		}

		span[data-type='svg'] {
			width: $base3;

			@include media('>xSmall') {
				width: $base5;
			}
		}
	}

	&__pagination {
		max-width: $blockContentMaxWidth;
		margin: $base3 auto $base2 auto;

		@include media($columnsBreakpoint) {
			margin-bottom: $base7;
		}
	}

	&__partnerImage {
		margin-bottom: $base;

		@include media($columnsBreakpoint) {
			margin-bottom: $base3;
		}

		img {
			width: 100%;
		}
	}
}

@import './block-elements/content';
@import './block-elements/bg-img';
@import './block-elements/title';
@import './block-elements/text';
@import './block-elements/full-img';
