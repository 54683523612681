.button {
	@include button($base);
	@include ease(color background-color border-color, $hoverDuration);
	padding: $base $base2;
	font-weight: normal;
	font-size: inherit;
	position: relative;
	background-color: var(--mainAccentColor);
	color: $baseBgColor;
	border: 1px solid transparent;
	width: 100%;

	@include media('>xSmall') {
		width: auto;
	}

	@include media($columnsBreakpoint) {
		width: auto;
	}

	&:not(:disabled):focus,
	&:not(:disabled):hover,
	&:not(:disabled):active {
		background-color: var(--secondaryAccentColor);
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	&--menuToggler {
		@include font(detail);
		border-bottom: 2px solid currentColor;
		border-top: 2px solid currentColor;
		color: inherit;
		background-color: transparent;
		padding: $baseHalf 0;
		line-height: 1;
		width: auto;

		@include media($columnsBreakpoint) {
			display: none;
		}

		&.js-toggled {
			border-bottom-color: transparent;
			border-top-color: transparent;
		}
	}

	&--upload {
		overflow: hidden;
	}

	&--seamless,
	&--accountSecondary {
		@include ease(border-bottom color, $duration2);
		background-color: transparent;
		border: 0;
		border-bottom: 1px transparent solid;
		color: currentColor;
		min-width: auto;
		padding: 0;
		width: auto;

		&:not(:disabled):focus,
		&:not(:disabled):hover,
		&:not(:disabled):active {
			background-color: transparent;
			color: var(--mainAccentColor);
		}

		.applicationNav__actionWrapper--back & {
			padding-top: calc(#{$base} + 1px);
		}
	}

	&--seamless {
		@include app-menuHoverEffect();
	}

	&--accountSecondary {
		padding: 0;
	}

	&--linkedGuest {
		pointer-events: none;
	}

	&--fieldRetry {
		@include font(detail);
		color: $errorColor;
		display: none;
		text-decoration: underline;

		.field[data-save-status='error'] & {
			display: inline-flex;
		}
	}

	&--pdf {
		@include font(detail);
	}

	&--overlayClose {
		background-color: transparent;
		border: 0;
		color: currentColor;
		height: $base4;
		line-height: 0;
		min-width: auto;
		width: $base4;
	}

	&--removeImage {
		padding: $base * 0.9;

		&::before {
			display: none;
		}
	}

	&--colorSchemeAlternative {
		&:hover,
		&:active {
			background-color: $alternativeBgColor;
		}
	}

	&--colorSchemeInverted {
		background: transparent;
		border: 1px solid $alternativeBgColor;

		&:focus,
		&:hover,
		&:active {
			border-color: $alternativeBgColor;
		}
	}

	&--colorSchemeDmp2019 {
		// empty, looking like the default
	}

	&--signatureAction {
		@include font(detail);
		padding: $baseHalf $base;
		line-height: 1;

		& + & {
			margin-left: $base;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	.blockTilesWall & {
		@include media('screen') {
			width: 100%;
			min-width: 0;
		}
	}

	.pagination & {
		min-width: 0;
	}

	&__icon {
		height: 1em;

		&--flipH {
			transform: scaleX(-1);
		}

		svg {
			fill: currentColor;
		}

		.button--overlayClose & {
			height: $base3;
			width: $base3;
		}

		.button--menuToggler &--menu,
		.button--menuToggler:not(.js-toggled) & {
			display: none;
		}
	}

	&__label {
		// border-top: 1px solid transparent;
		// border-bottom: 1px solid transparent;
		font-family: $fontFamilySecond;

		.button--fieldRetry & {
			border: 0;
		}

		.pagination__tile & {
			border: 0;
		}

		.form & {
			text-align: left;
		}

		.button--menuToggler & {
			padding-left: 0;
			margin-left: 0;
		}

		.button--menuToggler.js-toggled & {
			display: none;
		}
	}
}
