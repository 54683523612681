.body {
	@include font(base);
	margin: 0;
	padding: 0;

	&.js-noscroll {
		height: 100%;
		overflow: hidden;
	}

	&--embedded {
		display: flex;
		flex-direction: column;
	}
}
