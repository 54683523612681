
.iconButton {
	@include ease(color background-color, $hoverDuration);
	cursor: pointer;
	position: relative;
	background-color: $baseBgColor;
	color: $baseColor;
	display: inline-block;
	outline: none;
	border: 0;
	// margin: 0 $base $base 0;
	width: 100%;
	height: $iconBtnMobileHeight;

	@include media('>=small') {
		height: 100%;
		width: $iconBtnHeight;
	}

	html:not(.mobile) &:hover,
	html.mobile &:active {
		opacity: 0.7;
	}

	&:focus {
		outline: $base * 0.5 solid $hoverColor;
		background-color: $hoverColor;
	}



	.overlay__actions & {
		// margin: 0 0 0 $base;
	}

	.header__actions & {
		margin-top: 0;
		margin-bottom: 0;
		float: left;
		display: block;

		&:not(:active):not(:focus):not(:hover) {
			background-color: transparent;
		}

		&:last-child {
			margin-right: 0;
		}
	}


	span[data-type="svg"] {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: $base2;

		@include media('>=small') {
			width: $base3;

			.overlay__actions &,
			.overlay .pageActions & {
				width: $base2;
			}
		}

		svg {
			fill: currentColor;
			stroke: none;
		}
	}


	&__icon {
		&--loggedIn {
			display: none;

			body.js-loggedIn & {
				display: inline-block;
			}
		}

		&--loggedOut {
			display: none;

			body:not(.js-loggedIn) & {
				display: inline-block;
			}
		}

	}
}
