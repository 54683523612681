.block {
	&__text {
		margin-left: auto;
		margin-right: auto;
		// margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}

		.block__title + & {
			margin-top: $base3;

			@include media($columnsBreakpoint) {
				margin-top: $base5;
			}
		}

		.block--linkedTile &,
		.block--teaser &,
		.block--search & {
			@include font(firstHeadline);
		}

		&--important {
			@include font(thirdHeadline);
		}

		& + & {
			margin-top: $base2;
		}

		.block--video & {
			margin-top: $base2;

			@include media($columnsBreakpoint) {
				margin-top: $base4;
			}
		}

		.block--video .block__title + & {
			margin-top: $base;

			@include media($columnsBreakpoint) {
				margin-top: $base2;
			}
		}

		&--previewIntro {
			margin-bottom: $base4;
		}

		&--contractIntro {
			margin-bottom: $base4;
		}

		&--contractOuttro {
			margin-top: $base4;
		}

		blockquote {
			margin-left: 0;
		}
	}
}
