.overlay {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	min-height: 100vh;
	z-index: 5;
	padding: $base2 0;
	display: none;
	@include fadeOut($duration4);

	@include media($columnsBreakpoint) {
		padding: $base8 0;
	}

	&.js-beforeActive {
		display: block;
	}

	&.js-duringActive {
		@include fadeIn();
	}

	.body--embedded &--account {
		@include fadeIn();
		position: relative;
		order: 1;
		min-height: 0;
		display: block;
		padding: 0;

		@include media($columnsBreakpoint) {
			padding: 0;
		}
	}

	&::before {
		@include fadeOut($duration2);
		background: rgba($baseColor, 0.9);
		position: fixed;
		left: 0;
		right: 0;
		top: -50vh;
		bottom: -50vh;
		content: '';
	}

	.body--embedded &--account::before {
		display: none;
	}

	&.js-duringActive::before {
		@include fadeIn();
	}

	&__actions {
		@include app-sidePadding();
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	&__content {
		@include app-container;
		position: relative;
		max-width: $overlayMaxWidth;

		.overlay:not(.js-beforeActive) & {
			height: 0;
			overflow: hidden;

			.body--embedded & {
				height: auto;
				overflow: visible;
			}
		}

		.overlay.js-largeWidth & {
			max-width: $overlayLargeMaxWidth;
		}

		.body--embedded .overlay--account & {
			max-width: none;
			border-bottom: 1px solid currentColor;
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;

			@include media($columnsBreakpoint) {
				padding-left: 0;
				padding-right: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&__page {
		@include app-container();
		max-width: none;
		margin-left: 0;
		margin-right: 0;
		width: auto;
		background-color: $baseBgColor;
		position: relative;
		// margin-right: $base5;
		padding-top: $containerPaddingSmall;

		@include media($columnsBreakpoint) {
			padding-top: $containerPadding;
			margin-right: $iconBtnHeight + $base;
		}

		$minI: $overPageActionCount;
		min-height: $minI * $base5 - $base;

		@include media($columnsBreakpoint) {
			min-height: $minI * $base8 - $base;
		}

		&--btnCount5 {
			min-height: $base4 * 5 + $base * 4;

			@include media($columnsBreakpoint) {
				min-height: $base7 * 5 + $base * 4;
			}
		}
	}
}
