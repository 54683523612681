.loader {
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: transparentize($baseBgColor, 1);
	overflow: hidden;
	visibility: hidden;
	@include ease(all, $duration2);

	&.js-loading {
		visibility: visible;
	}


	&.js-cover {
		background-color: $baseBgColor;
	}
}
