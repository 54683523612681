.account {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	width: auto;
	background-color: $baseBgColor;

	&__block {
		@include app-container();
		max-width: none;
		@include ease(visibility opacity, $duration2);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;

		@include media($columnsBreakpoint) {
			padding-top: $containerPadding;
			padding-bottom: $containerPadding;
		}

		&.js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}

		.body--embedded & {
			padding: 0;

			@include media($columnsBreakpoint) {
				padding: 0;
			}
		}
	}

	&__form {
		.body.js-linkedGuest &,
		.body--withLoginToken & {
			display: none;
		}
	}

	&__message {
		margin-bottom: $base5;

		p {
			margin-top: $base2;
		}

		.body--withLoginToken & {
			display: none;
		}
	}

	&__notice {
		.body--withLoginToken & {
			display: none;
		}
	}

	&__title {
		@include font(firstHeadline);

		.body.js-linkedGuest &,
		.body--withLoginToken & {
			display: none;
		}
	}

	&__loginTokenExpired {
		display: none;
		justify-content: center;
		align-items: center;
		min-height: 80vh;

		.body--withLoginToken & {
			display: flex;
		}
	}

	&__linkedGuestLoggedOut {
		display: none;
		justify-content: center;
		align-items: center;
		min-height: 80vh;

		.body.js-linkedGuest & {
			display: flex;
		}
	}
}
