.block {
	&__content {
		position: relative;
		z-index: 1;
		display: block;
		max-width: $blockContentMaxWidth; //$mainxMaxWidth;
		width: 100%;
		margin: 0 auto;

		.body--embedded & {
			max-width: none;
		}

		.block--slideshow.block--widthMax &,
		.block--fullImg &,
		.block--bgVideo &,
		.block--fullSize & {
			max-width: none;
		}

		.block--alignRight & {
			text-align: right;
		}

		.block--tilesWall > & {
			margin-bottom: -$base2;

			@include media($columnsBreakpoint) {
				margin-bottom: -$base3;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		.blockTilesWall--widthMax > & {
			max-width: none;
		}

		.block--publication & {
			height: auto;
			//margin-top: $base2;

			&::before {
				display: none;
			}

			.blocks > & {
				@include media($columnsBreakpoint) {
					height: 0;
					padding: 0 $base3;
				}
			}

			.blocks > &::before {
				@include media($columnsBreakpoint) {
					content: '';
					display: block;
					height: $base3;
				}
			}
		}

		.block--video & {
			order: 1;
		}

		.overlay & {
			max-width: none;
		}

		.block--bgVideo:first-child & {
			padding-top: $headerMobileHeight;

			@include media($columnsBreakpoint) {
				padding-top: 0;
			}
		}
	}
}
