.all {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	visibility: visible;
	position: relative;
	z-index: 2;
	font-family: $fontFamilyMain;

	&.js-inactive {
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 1;
	}

	&.js-covered {
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 1;
	}

	.body--embedded & {
		order: 2;
		min-height: 0;

		&.js-inactive,
		&.js-covered {
			position: relative;
			top: 0 !important;
		}
	}

	&::after {
		@include fadeOut($duration2);
		background: rgba($baseColor, 0.9);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: '';
		z-index: 2;
	}

	&.js-coveredByMenu::after {
		@include fadeIn();
	}

	&.js-loading::after {
		@include fadeIn();
		z-index: 2;
	}
}
