.main {
	flex-grow: 1;
	position: relative;
	z-index: 2;
	background-color: $baseBgColor;

	&--panel {
		min-height: calc(100vh - #{$base8 * 2});
	}

	.body--withLoginToken & {
		display: flex;
		width: 100%;
	}
}
