.header {
	@include app-container();
	@include ease(transform, $duration3);
	background-color: $headerBgColor;
	border-bottom: solid $headerLineSize $headerLineColor;
	display: flex;
	// align-items: stretch;
	max-width: none;
	min-height: $headerMobileHeight;
	padding-top: $headerSpacingSmall;
	padding-bottom: $headerSpacingSmall;
	position: fixed;
	top: 0;
	transform: translateY(0%);
	width: 100%;
	z-index: 3;

	.body--withLoginToken & {
		display: none;
	}

	body.js-editApplication & {
		position: absolute;
	}

	@include media($columnsBreakpoint) {
		min-height: $headerHeight;
		padding-top: $headerSpacingLarge;
		padding-bottom: $headerSpacingLarge;
	}

	&.js-collapsed:not(.js-coveredByMenu) {
		transform: translateY(-100%);

		body.js-editApplication & {
			transform: none;
		}
	}

	&.js-open {
		position: relative;
		transform: none;
		border-bottom: 0;
	}

	&__container {
		flex-grow: 1;
		position: relative;
		// height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	&__logo {
		display: block;
		flex-grow: 0;
	}

	.header__qrCode {
		@include media('screen') {
			display: none;
		}
	}
}
