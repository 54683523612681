.block {
	&__title {
		@include font(firstHeadline);
		display: block;

		.block--tilesWall & {
			@include font(secondHeadline);
		}

		.block--video & {
			margin-top: $base2;

			@include media($columnsBreakpoint) {
				margin-top: $base3;

				.overlay & {
					margin-top: $base5;
				}
			}
		}

		.block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

			@include media($columnsBreakpoint) {
				margin-top: $blockVerticalPadding;
			}
		}

		.block--tile.block--publication & {
			margin-top: $base2;

			@include media($columnsBreakpoint) {
				margin-top: $base3;
			}
		}
	}
}
