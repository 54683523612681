@use 'sass:math';

$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(16),
		font-weight: normal,
		line-height: math.div(24, 16),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(20),
				line-height: math.div(28, 20),
			),
		),
	),
	firstHeadline: (
		font-family: $fontFamilySecond,
		font-size: pxToRem(22),
		font-weight: normal,
		line-height: math.div(29, 22),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(48),
				line-height: math.div(62, 48),
			),
		),
	),
	secondHeadline: (
		font-family: $fontFamilySecond,
		font-size: pxToRem(18),
		font-weight: normal,
		line-height: math.div(26, 18),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(26),
				line-height: math.div(34, 26),
			),
		),
	),
	thirdHeadline: (
		font-family: $fontFamilySecond,
		font-size: pxToRem(16),
		font-weight: normal,
		line-height: math.div(24, 16),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(20),
				line-height: math.div(28, 20),
			),
		),
	),
	detail: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(14),
		font-weight: normal,
		line-height: math.div(20, 14),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(16),
				line-height: math.div(24, 16),
			),
		),
	),
	blanksLabel: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(11),
		font-weight: normal,
		line-height: 1,
		text-transform: uppercase,
	),
	detailHighlighted: (
		font-family: $fontFamilySecond,
		font-size: pxToRem(14),
		font-weight: normal,
		line-height: math.div(20, 14),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(16),
				line-height: math.div(24, 16),
			),
		),
	),
	menuNav: (
		font-family: $fontFamilySecond,
		font-size: pxToRem(16),
		font-weight: normal,
		line-height: math.div(26, 18),
		variants: (
			$columnsBreakpoint: (
				font-family: $fontFamilyMain,
				font-size: pxToRem(18),
			),
		),
	),
	caption: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(14),
		font-weight: normal,
		line-height: math.div(20, 14),
		variants: (
			$columnsBreakpoint: (
				font-size: pxToRem(16),
				line-height: math.div(24, 16),
			),
		),
	),
) !default;
