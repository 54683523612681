.preview {
	position: relative;
	color: $baseColor;
	padding: $base2;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.previewList--winners & {
		padding: $base2 0;
	}

	&__category {
		@include font(detail);
		width: 100%;
		margin-bottom: $base;
	}

	&__title {
		width: 100%;
		margin-bottom: $base2;
	}

	&__thumb {
		display: none;

		figure,
		img {
			width: 100%;
		}

		@include media($columnsBreakpoint) {
			display: block;
			width: $previewThumbWidth;
			margin-right: $base2;
		}
	}

	&__description {
		width: 100%;

		@include media($columnsBreakpoint) {
			width: calc(100% - #{$previewThumbWidth} - #{$base2});
		}
	}
}
