@mixin app-link($inverted: false) {
	@include ease(border-bottom color, 0.4s);
	display: inline;
	color: $baseColor;
	text-decoration: none;
	border-bottom: solid 1px var(--mainAccentColor);

	@if ($inverted) {
		color: $invertedColor;
		border-bottom: solid 1px $invertedColor;
	}

	@include onHover() {
		border-bottom-color: var(--secondaryAccentColor);
		color: $baseColor;
	}
}
