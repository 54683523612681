.notification {
	@include font(base);
	padding: $base4 $base2;
	color: $notificationColor;
	background-color: $notificationBgColor;

	&.js-active {
		opacity: 1;
		transform: translateY(100%);
	}


	&__container {
		position: relative;
		margin: auto;
		max-width: $notificationMaxWidth;
		text-align: left;
	}


	&__text {
		a {
			color: inherit;
		}
	}
}
