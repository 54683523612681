.logo {
	color: $baseColor;
	display: block;
	height: $headerMobileHeight;
	padding-right: $base1;

	@include media($columnsBreakpoint) {
		height: $headerHeight;
	}

	span[data-type='svg'] {
		height: 100%;

		svg {
			width: auto;
			height: 100%;
			fill: $baseColor;
		}
	}

	img {
		height: $headerMobileHeight;

		@include media($columnsBreakpoint) {
			height: $headerHeight;
		}
	}
}
