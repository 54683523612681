@use 'sass:math';

.applicationView {
	&__blankValue {
		border-bottom: 1px solid $baseLineColor;
		font-weight: bold;
	}

	&__section {
		& + & {
			margin-top: $base7;
		}
	}

	&__sectionTitle {
		margin-bottom: $base3;
	}

	&__footer {
		margin-bottom: $base4;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__footerText {
		margin-top: $base4;
		width: 100%;
	}

	&__footerDate {
		border-bottom: 1px solid currentColor;
		margin-top: $base4;
		padding-bottom: $base4;
		width: 100%;

		@include media('>=small') {
			width: 30%;
		}
	}

	&__footerSignature {
		border-bottom: 1px solid currentColor;
		margin-top: $base4;
		padding-bottom: $base4;
		width: 100%;

		@include media('>=small') {
			width: 30%;
		}
	}

	&__field {
		border-bottom: $borderSize solid $baseLineColor;
		padding-top: $base2;
		padding-bottom: $base2;

		&:first-child {
			border-top: $borderSize solid $baseLineColor;
		}

		@include media('>=small') {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&--image {
			@include media('>=small') {
				flex-direction: row-reverse;
			}
		}
	}

	&__fieldLabel {
		@include font(thirdHeadline);
		padding-bottom: $base * 0.5;

		@include media('>=small') {
			padding-bottom: 0;
			padding-right: $base2;
			width: 40%;
		}

		.applicationView__imageWrapper & {
			display: none;
		}
	}

	&__fieldValue {
		@include font(base);
		@include media('>=small') {
			width: 60%;
		}

		.applicationView__imageWrapper & {
			display: none;
		}
	}

	&__imagefield {
		& + & {
			margin-top: $base2;
		}
	}

	&__imagefieldLabel {
		font-weight: bold;
		padding-bottom: $base * 0.5;
	}

	&__imageWrapper {
		padding-bottom: $base2;

		@include media('>=small') {
			padding-bottom: 0;
			width: 60%;
		}
	}

	&__imageFields {
		@include media('>=small') {
			width: 40%;
			padding-right: $base2;
		}
	}

	&__image {
		width: 100%;
		height: auto;

		.applicationView &--signature {
			max-width: 600px;
		}
	}
}
