.page {
	@include ease(opacity visibility, $duration4);

	&.js-leaving {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		opacity: 0;
		visibility: hidden;
	}

	&.js-entering {
		position: relative;
		z-index: 1;
	}

	&--editSignature {
		width: 100%;
		padding: $base;
	}

	&__form {
		* + & {
			margin-top: $base4;
		}
	}

	&__applicationContractLogo {
		display: none;
	}
}
