@use 'sass:math';
.field {
	margin-top: $fieldTopSpacing;
	width: 100%;

	@include media($columnsBreakpoint) {
		margin-left: $base2;
	}

	&--signature {
		.page--editSignature & {
			margin-top: 0;
		}
	}

	&--imagefile {
		margin-top: getFontSize(detail) * getLineHeight(detail) + $fieldLabelSpacing + $fieldTopSpacing;

		@include media($columnsBreakpoint) {
			margin-left: $base2;
			margin-top: getFontSize(detail, $largeLayout) * getLineHeight(detail, $largeLayout) + $fieldLabelSpacing + $fieldTopSpacing;
		}

		&.js-progress::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 10;
		}
	}

	&--langSelectorRadio {
		// override this in the project style to enable lang selector for that project
		display: none;

		.menu & {
			display: none;
			margin: $base 0;

			.body.js-linkedGuest & {
				// override this in the project style to enable lang selector for that project
				display: none;
			}

			@include media($columnsBreakpoint) {
				margin: $base 0;
			}
		}
	}

	&--text,
	&--password,
	&--select,
	&--file,
	&--date {
		.field--textarea + &,
		.field--tags + & {
			margin-top: $base5;
		}

		@include media($columnsBreakpoint) {
			flex-grow: 0;
			flex-shrink: 0;
			width: calc(50% - #{$base2});
		}

		.application .field--group & {
			@include media($columnsBreakpoint) {
				width: 100%;
			}
		}
	}

	&--textarea + &:not(&--textarea):not(&--tags),
	&--textarea + &:not(&--textarea):not(&--tags) + &:not(&--textarea):not(&--textarea),
	&--tags + &:not(&--textarea):not(&--tags),
	&--tags + &:not(&--textarea):not(&--tags) + &:not(&--textarea):not(&--textarea) {
		margin-top: $base5;
	}

	&--textarea,
	&--tags {
		margin-top: $base5;

		&:first-child {
			margin-top: $base3;
		}
	}

	&__container {
		.field .field & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__content {
		.field--disabled & {
			opacity: $disabledFieldOpacity;
		}

		.field--group.field--hasActions > .field__container > &--hasActions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.field--group.field--sortableItem > .field__container > & {
			position: relative;
			padding-left: $base3;
		}

		.field--blanks & {
			display: flex;
			flex-direction: column;
		}
	}

	&__fields {
		position: relative;
		z-index: 1;

		@include media($columnsBreakpoint) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-left: -$base2;
			margin-top: -$base3;
		}

		.application .field--group & {
			@include media($columnsBreakpoint) {
				flex-direction: row-reverse;
			}

			&--onlyImages {
				@include media($columnsBreakpoint) {
					flex-direction: row;
				}
			}
		}
	}

	&__actions {
		.field--group.field--hasActions > .field__container > .field__content--hasActions > & {
			flex-grow: 0;
			flex-shrink: 0;
		}

		&--array {
			margin-top: $base2;
		}
	}

	&__label {
		@include font(detail);
		display: block;
		flex-grow: 1;

		position: relative;
		margin-bottom: $fieldLabelSpacing;
		font-weight: 400;

		@include onHover() {
			opacity: 0.7;
		}

		.menu .field--langSelectorRadio .field__info & {
			display: none;
		}

		.field__input--radio:focus + &,
		.field__input--checkbox:focus + & {
			outline: 1px solid $focusColor;
		}

		.field--radio.field--required &--inputLabel {
			&::after {
				display: none;
			}
		}

		.field--checkbox &,
		.field--radio &--inputLabel {
			cursor: pointer;
			display: inline-block;
			position: relative;
			padding-left: $base3;
			min-height: $base * 2.4;
		}

		.field--checkbox & {
			margin-bottom: 0;
			display: flex;
			align-items: flex-start;
		}

		.field--radio &--inputLabel {
			display: inline-flex;
			align-items: center;
			margin-right: $base2;

			&:last-child {
				margin-right: 0;
			}
		}

		.application &:not(.field__label--inputLabel) {
			padding-right: $base2;
		}
	}

	&__labelValue {
		.field--required > .field__container > .field__content > .field__label > & {
			> :last-child::after {
				content: '*';
			}
		}

		.field--required > .field__container > .field__content > .field__info .field__label > & {
			> :last-child::after {
				content: '*';
			}
		}
	}

	&__inputIcon {
		@include ease(color opacity, $duration2);
		position: absolute;
		left: 0;
		top: 0;
		// transform: translateY(-50%);
		width: $base * 2.4;
		height: $base * 2.4;
		color: inherit;

		span[data-type='svg'] {
			color: inherit;
			width: 100%;
			height: auto;
		}

		.field__input:focus + .field__label & {
			color: $baseColor;
		}

		.field__input:checked + .field__label &--unchecked,
		.field__input:not(:checked) + .field__label &--checked {
			opacity: 0;
		}
	}

	&__selectIcon {
		position: absolute;
		right: 0;
		top: 0;
		width: $base4;
		height: 100%;
		color: $baseColor;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		z-index: 2;
	}

	&__inputWrapper {
		position: relative;

		.field--select & {
			background-color: $baseBgColor;
		}

		.field--blanks & {
			order: 3;
		}
	}

	&__input {
		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}

		&--text,
		&--password,
		&--textarea,
		&--tags,
		&--select,
		&--date,
		&--time {
			@include ease(border-left-color, $duration2);
			@include font(base);
			font-family: $fontFamilyMain;
			border: 1px solid $baseColor;
			width: 100%;
			padding: $base1 ($base * 0.7) $base1 $base1;
			color: $baseColor;
			background-color: $baseBgColor;
			border-radius: 0;

			@include onHover {
				background-color: $hoverColor;
			}

			&:focus {
				background-color: $hoverColor;
				outline: none;
			}

			.field.js-invalid & {
				border-color: $errorColor;
			}
		}

		&--textarea,
		&--tags {
			min-height: $minTextareaHeight;
			max-height: $maxTextareaHeight;
			padding: $base $base ($base * 1.5) $base2;
		}

		&--select {
			position: relative;
			z-index: 2;
			-webkit-appearance: none;
			-moz-appearance: none;
			background-color: transparent;
			padding-right: $base4;

			option {
				@include font(base);
				font-family: $fontFamilyMain;
				padding: ($base * 0.5) $base;
			}
		}

		&--file {
			font-size: 200px;
			position: absolute;
			top: 0;
			right: 0;
			opacity: 0;
			z-index: 2;
			cursor: pointer;
		}

		.field--passwordlessLogoutEmail & {
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			border: 0;
			font-weight: bold;
		}
	}

	&__datetimeWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__datetimeDate,
	&__datetimeTime {
		flex-shrink: 0;
	}

	&__datetimeDate {
		flex-grow: 1;
		margin-right: $base2;
	}

	&__datetimeTime {
		flex-grow: 0;
	}

	&__dateWrapper {
		@include dynamicColumns($base);
		justify-content: flex-start;
	}

	&__datePart {
		position: relative;
		width: dynamicColumnSize(1, 1, $base);

		&--day {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(3, 12, $base);
			}
		}

		&--month {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(6, 12, $base);
			}
		}

		&--year {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(3, 12, $base);
			}
		}
	}

	&__description {
		padding-top: $base;
	}

	&__extra {
		@include font(detail);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__errors {
		flex-shrink: 1;
		flex-grow: 1;

		.field--blanks & {
			@include font(detail);
			order: 2;
		}

		.field--blanks .field__extra & {
			display: none;
		}
	}

	&__charsCounter {
		padding-top: $base * 0.5;
		padding-left: $base2;
		flex-shrink: 0;
		flex-grow: 0;

		&.js-negative {
			color: $errorColor;
		}
	}

	&__error {
		padding-top: $base * 0.5;
		color: $errorColor;
	}

	&__sortableHandle {
		position: absolute;
		left: -$base * 0.5;
		top: $base;
		width: $base2;
		cursor: ns-resize;
	}

	&__labelRequired,
	&__labelOptional {
		// display: none;
	}

	&__fileButtonContainer {
		position: relative;
	}

	&__fileUploadProgress {
		background-color: currentColor;
		position: relative;
		height: 2px;
		visibility: hidden;
		width: 0%;

		.field.js-progress & {
			visibility: inherit;
		}
	}

	&__imagefileFieldWrapper {
		width: 100%;

		@include media($columnsBreakpoint) {
			flex-grow: 0;
			flex-shrink: 0;
			width: calc(40% + #{$base2});
			padding-left: $base2;
			margin-right: $base2;
		}

		.field__fields--onlyImages & {
			@include media($columnsBreakpoint) {
				position: relative;
				padding-left: 0;
				margin-top: -$base3;
			}
		}
	}

	&__imageFieldsWrapper {
		width: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__image {
		position: relative;
	}

	&__imageUpload {
		margin-top: $base2;

		.field.js-progress & {
			opacity: 0.3;
		}
	}

	&__fileInfo {
		@include font(detail);
		margin-top: $base;

		.field.js-empty:not(.js-progress) & {
			display: none;
		}
	}

	&__limitsInfo {
		@include font(detail);
		margin-top: $base;
	}

	&__imageProgress {
		@include font(thirdHeadline);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 3;
		font-weight: bold;
		display: none;

		.field.js-progress & {
			display: block;
		}
	}

	&__removeFile {
		margin-top: $base2;

		@include media($columnsBreakpoint) {
			bottom: 0;
			margin-top: 0;
			min-width: 0;
			position: absolute;
			right: 0;
			width: auto;
			z-index: 3;
		}

		.field.js-empty &,
		.field.js-progress & {
			display: none;
		}
	}

	&__removeImage {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
		width: auto;
		min-width: 0;

		.field.js-empty &,
		.field.js-progress & {
			display: none;
		}
	}

	&__imgPlaceholder {
		position: relative;
		display: none;
		width: 100%;
		border: 1px solid $baseColor;

		.field.js-empty:not(.js-progress) & {
			display: block;
		}

		&::before {
			content: ' ';
			display: block;
			padding-bottom: math.div(1, $videoPlayerRatio) * 100%;
		}
	}

	&__imgPlaceholderLabel {
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		padding: $base2;
		text-align: center;
	}

	&__img {
		position: relative;
		width: 100%;
		height: auto;
		z-index: 1;

		.field.js-empty & {
			display: none;
		}

		.field.js-progress & {
			display: block;
			opacity: 0.5;
		}

		&--progress {
			@include ease(clip, $duration2);
			position: absolute;
			left: 0;
			top: 0;
			clip: rect(0, 0, auto, auto);
			display: none;
			z-index: 2;

			.field.js-progress & {
				display: block;
				opacity: 1;
			}
		}
	}

	&__checkIcon {
		// position: absolute;
		// bottom: 0;
		// right: 0;
		width: $base2;
		height: $base2;
		color: $checkIconColor;
		// visibility: hidden;
		clip: rect(auto, 0%, auto, auto);
		// display: none;

		.application & {
			display: block;
		}

		span[data-type='svg'] {
			width: 100%;
		}
	}

	&__info {
		display: flex;
		align-items: flex-start;

		.field--blanks & {
			order: 1;
		}
	}

	&__signatureActions {
		margin-top: $base;
		display: flex;
	}

	&__signatureNameInput {
		@include font(detail);
		border: 1px solid $baseLineColor;
		width: 100%;
		max-width: pxToRem(600);
		background-color: transparent;

		.page--editSignature & {
			display: none;
		}
	}

	&__signatureCanvas {
		border: 1px solid $baseLineColor;
		width: calc(var(--width) * 1px);
		height: calc(var(--height) * 1px);
		flex-shrink: 0;
		flex-grow: 0;
		cursor: crosshair;

		* + & {
			margin-top: $base;

			.page--editSignature & {
				margin-top: 0;
			}
		}

		&:focus {
			outline: 0;
		}
	}

	&__signatureCanvasArea {
		position: relative;
	}

	&__signatureContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;

		@include media($mediumBreakpoint) {
			flex-direction: row;
		}
	}

	&__signatureEditOnMobile {
		padding-top: $base2;

		@include media($mediumBreakpoint) {
			padding-left: $base2;
			padding-top: 0;
		}

		.page--editSignature &,
		.html.mobile & {
			display: none;
		}
	}

	&__signatureOnMobile {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		min-height: 100%;
		display: none;
		flex-direction: column;
		background-color: $baseBgColor;

		@include media($columnsBreakpoint) {
			flex-direction: row;
		}

		.field.js-signatureOnMobile & {
			display: flex;
		}
	}

	&__signatureEditOnMobileMessage {
		@include font(detail);
		margin-bottom: $base;
	}

	&__signatureOnMobileQrCode {
		height: pxToRem(200) + $base;
		width: pxToRem(200) + $base2;
		padding-right: $base2;
		padding-bottom: $base;
		flex-shrink: 0;

		a,
		svg {
			display: inline-block;
			width: pxToRem(200);
			height: pxToRem(200);
		}
	}

	&__signatureSignOnMobileMessage {
		@include font(detail);
		flex-grow: 1;
	}

	&__signatureSignOnMobileOrientationMessage {
		position: fixed;
		z-index: 100;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: none;
		justify-content: center;
		align-items: center;
		padding: $base;
		background-color: $baseBgColor;
		color: $baseColor;
		text-align: center;

		.page--editSignature & {
			@include media(portrait, 'width<=640px') {
				display: flex;
			}
		}
	}

	&__signatureUnfitOrientationMessage {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		min-height: 100%;
		display: none;
		background-color: $baseBgColor;
		justify-content: center;
		text-align: center;
		align-items: center;
		padding: $base;

		@include media(portrait, 'width<=640px') {
			display: flex;
		}
	}

	&__status {
		display: none;
		align-items: center;
		flex-shrink: 0;
		flex-grow: 0;

		.application & {
			display: flex;
		}

		.field--checkbox & {
			margin-left: $base;
		}
	}

	&__statusIcon {
		height: $base2;
		position: relative;
		width: $base2;
		display: none;

		&--error {
			color: $errorColor;
			margin-right: $base;

			.field[data-save-status='error'] & {
				display: inline-block;
			}
		}

		&--progress {
			animation-name: spin;
			animation-duration: 1s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;

			.field[data-save-status='progress'] & {
				display: inline-block;
			}
		}

		&--success {
			color: $checkIconColor;

			.field[data-save-status='success'] & {
				display: inline-block;
			}
		}

		span[data-type='svg'] {
			width: 100%;
		}
	}

	.field &__blanksInput {
		background-color: $baseBgColor;
		border: 0;
		border-bottom: 1px dotted $baseLineColor;
		border-left: 1px dotted $baseLineColor;

		line-height: inherit;
		font-size: inherit;
		min-width: 10em;
		max-width: $textMaxWidth;
		font-weight: bold;

		&:focus {
			outline: 1px solid $baseLineColor;
			// background-color: #ffffff;
		}
	}

	&__blanksInputBox {
		display: inline-block;
		vertical-align: baseline;
		padding-top: $base * 1.3;
		position: relative;
	}

	&__blanksInputLabel {
		@include font(blanksLabel);
		color: lighten($baseColor, 40%);
		border-bottom: 1px dotted $baseLineColor;
		white-space: nowrap;
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 0;
		line-height: $base;
	}

	&__blanksText {
		@include font(detail);
	}
}
