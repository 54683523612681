@use 'sass:math';
// user generated content Style:

.ugc {
	img {
		max-width: 100%;
	}

	q,
	blockquote {
		quotes: '»' '«' '\00AB''\00BB';
	}

	blockquote {
		margin: 0 0 0 1em;

		> p:first-child::before {
			content: open-quote;
		}

		> p:last-child::after {
			content: close-quote;
		}
	}

	cite {
		font-style: normal;
		font-weight: bold;

		&::before {
			content: '\2014';
			margin-right: 0.5em;
		}
	}

	blockquote + p > cite {
		display: block;
		margin-top: $base;
	}

	figure {
		&.video {
			position: relative;

			&::before {
				content: '';
				display: block;
				padding-bottom: math.div(1, $videoPlayerRatio) * 100%;
			}

			> iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	li {
		margin: 0;
	}

	ol > li {
		padding-left: 1.8em;
		counter-increment: ol-counter;
		position: relative;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: counter(ol-counter) '.';
		}
	}

	ol {
		margin: 0;
		list-style: none;

		counter-reset: ol-counter;
	}

	ul {
		margin: 0;
		list-style: none;
	}

	ul > li {
		position: relative;
		padding-left: 1.8em;

		&::before {
			content: '⦁';
			// transform-origin: 0 50%;
			// transform: scale(1.5);
			left: 0;
			position: absolute;
			top: 0;
		}
	}

	dt {
		font-weight: bold;
		font-style: normal;
	}

	dd {
		text-indent: 0;
		margin-left: 0;
	}

	dd + dd {
		margin-top: $base;
	}

	dd + dt {
		margin-top: $base2;
	}

	* + dl {
		margin-top: $base2;
	}

	a {
		@include app-link();
	}

	iframe {
		max-width: 100%;
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid currentColor;
		margin: 1em 0;
		padding: 0;
	}

	&.block__text--contract {
		hr {
			margin: 2em 0;
		}
	}

	h2 + * {
		margin-top: $base3;

		@include media($columnsBreakpoint) {
			margin-top: $base5;
		}
	}

	* + h3 {
		margin-top: $base5;

		@include media($columnsBreakpoint) {
			margin-top: $base7;
		}
	}

	h2 + h3 {
		margin-top: $base3;

		@include media($columnsBreakpoint) {
			margin-top: $base5;
		}
	}

	h3 + * {
		margin-top: $base;
	}

	* + figure {
		margin-top: $base3;

		@include media($columnsBreakpoint) {
			margin-top: $base5;
		}
	}

	h3 + figure {
		margin-top: $base2;

		@include media($columnsBreakpoint) {
			margin-top: $base3;
		}
	}

	figure + * {
		margin-top: $base3;

		@include media($columnsBreakpoint) {
			margin-top: $base5;
		}
	}

	* + p,
	* + ul,
	* + ol {
		margin-top: $base;
	}

	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base2;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figcaption,
li {
	.ugc &,
	&.ugc {
		max-width: $textMaxWidth;

		.block--alignCenter & {
			margin-left: auto;
			margin-right: auto;
		}

		.block--alignRight & {
			margin-left: auto;
		}
	}
}
