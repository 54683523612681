@use 'sass:math';
$base: 1rem !default;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base * 0.5;

$borderSize: 2px !default;
$mainMaxWidth: $base * 140 !default;
$overlayMaxWidth: $base * 112 !default;
$overlayLargeMaxWidth: $base * 140 !default;
$textMaxWidth: $base * 81 !default;

$blockMobileVerticalPadding: $base2 !default;
$blockVerticalPadding: $base7 !default;
$blockContentMaxWidth: $base * 102 !default;
$minSearchBlockHeight: 80vh !default;

$mainPadding: 5vw !default;

$containerPadding: $base3 !default;
$containerPaddingSmall: $base2 !default;

$iconBtnHeight: $base4 !default;
$iconBtnMobileHeight: $base * 2.9 !default;

$btnWidth: $base * 30 !default;
$btnWidthSmall: $btnWidth - $base2 !default;

$videoPlayerRatio: math.div(16, 9) !default;
$blockBgImageRatio: math.div(16, 8) !default;

$headerHeight: $base4 !default;
$headerMobileHeight: $base * 2.9 !default;
$headerLineSize: 1px !default;
$headerLineHoverSize: 1px !default;
$subMenuSmallerHeight: $base5 !default;

$headerSpacingSmall: $base2 !default;
$headerSpacingLarge: $base5 !default;

$logoHeightSmall: $base2 + 0.9rem !default;
$logoHeightLarge: $base5 !default;
$logoDotSize: $base * 0.9 !default;

$searchThumbWidth: 20% !default;
$previewThumbWidth: 20% !default;

$overPageActionCount: 4 !default;

$minTextareaHeight: $base * 15 !default;
$maxTextareaHeight: $base * 80 !default;

$applicationTabProgressHeight: 2px !default;

$notificationMaxWidth: $blockContentMaxWidth !default;

$fieldTopSpacing: $base3 !default;
$fieldLabelSpacing: $base1 !default;
