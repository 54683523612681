.footer {
	@include font(detail);
	background-color: $footerBgColor;
	padding-top: $base4;
	padding-bottom: $base6;

	.body--withLoginToken & {
		display: none;
	}

	&__container {
		@include app-container();
		max-width: $blockContentMaxWidth + $base6;
	}

	&__address {
		display: grid;
		gap: $base2;
		grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
		// @include dynamicColumns($base2, $base2);
		// margin: auto;
	}

	&__addressPart {
		// width: dynamicColumnSize(1, 1, $base2);

		// @include media($columnsBreakpoint) {
		// 	width: dynamicColumnSize(3, 12, $base2);
		// }
	}

	&__sponsor {
		width: dynamicColumnSize(1, 1, $base2);

		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(3, 12, $base2);
		}
	}

	&__sponsors {
		@include dynamicColumns($base2, $base2);
	}

	&__sponsorsIntro {
		width: dynamicColumnSize(1, 1, $base2);

		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(3, 12, $base2);
		}
	}

	&__sponsorImg {
		width: auto;
		height: auto;
	}
}
