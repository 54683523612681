.applicationSection {
	@include ease(visibility opacity, $duration4);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	visibility: hidden;
	opacity: 0;

	.body--embedded & {
		visibility: inherit;
		opacity: 1;
		position: relative;
		top: auto;
	}

	&:not(:first-child) {
		.body--embedded & {
			margin-top: $base5;
		}
	}

	&.js-current {
		position: relative;
		visibility: inherit;
		opacity: 1;
	}

	&__description {
		margin-bottom: $base5;
	}

	&__title {
		display: none;
		padding-top: $base5;
		border-top: 1px solid currentColor;
		margin-bottom: $base2;
		font-weight: bold;

		.applicationSection:first-child & {
			border-top: 0;
			padding-top: 0;
		}

		.body--embedded & {
			display: block;
		}
	}
}
