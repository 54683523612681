.menu {
	@include fadeOut($duration2);
	@include font(menuNav);
	display: none;
	flex-grow: 1;
	flex-shrink: 0;
	padding-bottom: $base4;
	padding-top: $base5;
	width: 100%;

	@include media($columnsBreakpoint) {
		@include fadeIn();
		display: block;
		padding-bottom: 0;
		padding-top: 0;
		width: auto;
	}

	.header.js-beforeActive & {
		display: block;
	}

	.header.js-duringActive & {
		@include fadeIn();
	}

	&__item {
		& + & {
			// margin-top: $base2;

			@include media($columnsBreakpoint) {
				margin-top: 0;
				margin-left: $base3;
			}
		}

		&--profile {
			.body.js-linkedGuest & {
				display: none;
			}
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include media($columnsBreakpoint) {
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	&__link {
		color: $baseColor;
		display: block;
		padding: $base 0;
		text-decoration: none;
	}

	&__linkLabel {
		@include ease(border-bottom-color color, $hoverDuration);
		color: $baseColor;
		border-bottom: 1px solid transparent;

		.menu__link:focus &,
		.menu__link:hover & {
			border-bottom: 1px solid currentColor;
			color: $hoverColorAlternate;
		}
	}
}
