@use 'sass:math';
.applicationNav {
	margin-top: $base10;
	border-top: 1px solid $baseLineColor;
	padding: $base2 0;

	.body--embedded & {
		display: none;
	}

	&__items {
		position: relative;
		overflow: hidden;
	}

	&__item {
		@include ease(visibility opacity, $duration4);
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;

		@include media($columnsBreakpoint) {
			justify-content: space-between;
			flex-direction: row;
		}

		&.js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}
	}

	&__actionWrapper {
		margin-top: $base;

		@include media($columnsBreakpoint) {
			margin-top: 0;
			flex-grow: 0;
			flex-shrink: 0;
			width: math.div(100%, 3);
		}

		&--back {
			text-align: center;
			padding-left: $base2;
			padding-right: $base2;
		}

		&:last-child {
			text-align: right;
		}
	}

	&__actionLabel {
		font-family: $fontFamilySecond;
	}

	// &__actionIcon {
	// 	position: relative;
	// 	display: inline-block;
	// 	height: 1em;
	// }
}
